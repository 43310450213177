import React from "react"
import ClientOnboarding from "../components/widgets/ClientOnboarding"
import TaskManagementAndReporting from "../components/widgets/TaskManagementAndReporting"
import ClientManagement from "../components/widgets/ClientManagement"
import AccountantsDashboardWidget from "../components/widgets/AccountantsDashboardWidget"
import ExpenseAndIncomeManagement from "../components/widgets/ExpenseAndIncomeManagement"
import { featuresPostTypeSlug } from "./Global"

// Hero Section
export const heroSection = {
  title: `Revolutionalise Your <span class="text-secondary">Accounting Practice</span> With Cutting Edge Software`,
  description: `Synkli transforms accounting practices for accountants to simplify workflows. It effectively bridges businesses and accountants to share, manage, and store client data, introducing paperless automation.`,
  btns: [
    {
      to: `https://partners.synkli.com.au/signup/`,
      href: `https://partners.synkli.com.au/signup/`,
      text: "Get started",
      ec: "primary-btn",
    },
    {
      to: "/contact-us/",
      href: "#",
      text: "Request a Demo",
      ec: "primary-transparent-btn",
    },
  ],
  widget: <AccountantsDashboardWidget />,
}

// Problems Section
export const problemSection = {
  title: `Problems Accountants Face`,
  description: `Common problems that every small business owner faces while managing his business`,
  topProblems: [
    {
      title: "Inefficient Client Onboarding",
      description:
        "Manual data entry and repetitive tasks slow down accountant onboarding. Automation is key to streamlining this process.",
      image: {
        path: "problem---inefficient-client-onboarding",
        alt: "problem---inefficient-client-onboarding",
      },
    },
    {
      title: "Incomplete Client Information",
      description:
        "Incomplete or inaccurate client information delays accounting processes, impacting service quality.",
      image: {
        path: "problem--incomplete-client-information",
        alt: "problem--incomplete-client-information",
      },
    },
    {
      title: "Lack of Client Standardisation",
      description:
        "Inconsistent client onboarding creates confusion, inefficiencies, and reduces overall effectiveness.",
      image: {
        path: "problem--lack-of-client-standardisation",
        alt: "problem--lack-of-client-standardisation",
      },
    },
    {
      title: "Untimely Client Responses",
      description:
        "Client delays in providing financial documents or responding to information requests can hinder timely accounting task completion.",
      image: {
        path: "problem--untimely-client-responses",
        alt: "problem--untimely-client-responses",
      },
    },
    {
      title: "Disorganised Client Records",
      description:
        "Poorly organised financial records hinder accountants from efficiently gathering necessary information.",
      image: {
        path: "problem--disorganised-client-records",
        alt: "problem--disorganised-client-records",
      },
    },
    {
      title: "Varied Document Formats",
      description:
        "Inconsistent formats for invoices, receipts, and bank statements increase processing time and data entry, reducing efficiency.",
      image: {
        path: "problem--varied-document-formats",
        alt: "problem--varied-document-formats",
      },
    },
    {
      title: "Client Communication Gaps",
      description:
        "Accountants may struggle to explain complex financial concepts, leading to client misunderstandings.",
      image: {
        path: "problem--client-communication-gaps",
        alt: "problem--client-communication-gaps",
      },
    },
  ],
  bottomProblems: [
    {
      title: "Limited Communication Channels",
      description:
        "Inefficient communication tools can hinder collaboration between accountants and clients, impacting service quality.",
      image: {
        path: "problem--limited-communication-channels",
        alt: "problem--limited-communication-channels",
      },
    },
    {
      title: "Security Concerns",
      description:
        "Securing sensitive client data is crucial but challenging, requiring robust measures to ensure confidentiality and client trust.",
      image: {
        path: "problem--security-concerns",
        alt: "problem--security-concerns",
      },
    },
    {
      title: "Client Data Accessibility",
      description:
        "Accessing or retrieving client data when needed can be challenging, disrupting workflow and affecting client satisfaction.",
      image: {
        path: "problem--client-data-accessibility",
        alt: "problem--client-data-accessibility",
      },
    },
    {
      title: "Client Data Organisation",
      description:
        "Managing a growing client base requires a well-organised data system to ensure accuracy.",
      image: {
        path: "problem--client-data-organisation",
        alt: "problem--client-data-organisation",
      },
    },
    {
      title: "Manual Financial Data Entry",
      description:
        "Manual entry of financial data from invoices and bank statements is time-consuming and error-prone.",
      image: {
        path: "problem--manual-financial-data-entry",
        alt: "problem--manual-financial-data-entry",
      },
    },
    {
      title: "Incomplete or Inaccurate Financial Data",
      description:
        "Missing or incorrect financial information can lead to inaccurate reports, impacting decision-making.",
      image: {
        path: "problem--incomplete-or-inaccurate-financial-data",
        alt: "problem--incomplete-or-inaccurate-financial-data",
      },
    },
    {
      title: "Lack of Automation in Financial Data Integration",
      description:
        "Manual financial data collection and integration processes are error-prone, emphasizing the need for automation.",
      image: {
        path: "problem--lack-of-automation-in-financial-data-integration",
        alt: "problem--lack-of-automation-in-financial-data-integration",
      },
    },
  ],
}

// Solutions Section
export const solutionsSection = {
  title: `Providing Accounting Solutions That Improves Accountant-Client Relationship, Efficiency and Productivity`,
  description: `Unlock efficiency and organisation for your accounting practice with our comprehensive management solutions. Streamline your operations and elevate your firm's performance effortlessly.`,
  firstColumnSolutions: [
    {
      SolutionCardEC: "",
      title: "Streamlined Client Workflows",
      description:
        "Synkli simplifies accounting tasks with automated data collection, online signature capabilities, and standardised forms for a smooth onboarding experience. This reduces the need for manual data entry and repetitive tasks.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---streamlined-client-workflows",
        alt: "solution---streamlined-client-workflows",
      },
      hoveredImage: {
        path: "solution---streamlined-client-workflows--hovered",
        alt: "solution---streamlined-client-workflows--hovered",
      },
    },
    {
      SolutionCardEC: "!mr-0 mt-10 md:mt-0 lg:mt-10",
      title: "Centralised Client Application",
      description:
        "Synkli offers clients a secure app to store all information accurately and comprehensively from the start. This reduces delays and errors by maintaining complete and precise data. They can easily upload documents, access information, and communicate seamlessly.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---centralised-client-application",
        alt: "solution---centralised-client-application",
      },
      hoveredImage: {
        path: "solution---centralised-client-application--hovered",
        alt: "solution---centralised-client-application--hovered",
      },
    },
    {
      SolutionCardEC: "!mr-0 mt-10 md:mt-0 lg:mt-10",
      title: "Client collaboration tools",
      description:
        "Utilise secure application for clients to upload documents, categorise expenses, and communicate directly within the platform. These tools also help accountants explain complex financial concepts effectively, ensuring clients fully understand their financial situation.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---client-collaboration-tools",
        alt: "solution---client-collaboration-tools",
      },
      hoveredImage: {
        path: "solution---client-collaboration-tools--hovered",
        alt: "solution---client-collaboration-tools--hovered",
      },
    },
  ],
  secondColumnSolutions: [
    {
      SolutionCardEC: "",
      title: "Pre-built templates",
      description:
        "Utilise customisable templates for client intake forms, checklists, and engagement letters to ensure consistency and efficiency. This allows consistency in clients onboarding processes and ensures a uniform process for all.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---pre-built-templates",
        alt: "solution---pre-built-templates",
      },
      hoveredImage: {
        path: "solution---pre-built-templates--hovered",
        alt: "solution---pre-built-templates--hovered",
      },
    },
    {
      SolutionCardEC: "!mr-0 mt-10 md:mt-0 lg:mt-10",
      title: "Access controls",
      description:
        "Define user roles and permissions for secure access to client information based on specific needs. You can dictate who should or shouldn’t have access to a client’s personal or business data.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---access-controls",
        alt: "solution---access-controls",
      },
      hoveredImage: {
        path: "solution---access-controls--hovered",
        alt: "solution---access-controls--hovered",
      },
    },
    {
      SolutionCardEC: "!mr-0 mt-10 md:mt-0 lg:mt-10",
      title: "Bank-grade security",
      description:
        "Implement industry-leading security protocols to safeguard sensitive client data and ensure compliance with regulations. These bank-grade security measures are to ensure that all information is kept confidential and protected.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---bank-grade-security",
        alt: "solution---bank-grade-security",
      },
      hoveredImage: {
        path: "solution---bank-grade-security--hovered",
        alt: "solution---bank-grade-security--hovered",
      },
    },
  ],
  thirdColumnSolutions: [
    {
      SolutionCardEC: "",
      title: "Mobile app access",
      description:
        "Empower clients to capture receipts, track expenses, and submit information on the go using our mobile app. This ensures that accountants can retrieve necessary information without disruption to their workflow.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---mobile-app-access",
        alt: "solution---mobile-app-access",
      },
      hoveredImage: {
        path: "solution---mobile-app-access--hovered",
        alt: "solution---mobile-app-access--hovered",
      },
    },
    {
      SolutionCardEC: "!mr-0 mt-10 md:mt-0 lg:mt-10",
      title: "Real-time communication tools",
      description:
        "Leverage built-in document sharing features for seamless collaboration with clients. This makes collaboration between accountants and clients more efficient and less time-consuming.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---real-time-communication-tools",
        alt: "solution---real-time-communication-tools",
      },
      hoveredImage: {
        path: "solution---real-time-communication-tools--hovered",
        alt: "solution---real-time-communication-tools--hovered",
      },
    },
    {
      SolutionCardEC: "!mr-0 mt-10 md:mt-0 lg:mt-10",
      title: "Interactive dashboards",
      description:
        "Provide clients with real-time access to their financial data through personalised dashboards for better financial understanding. As the client base grows, these dashboards provide a clear overview and easy navigation of client information.",
      button: {
        to: "/",
        href: "#",
        text: "See More",
      },
      image: {
        path: "solution---interactive-dashboards",
        alt: "solution---interactive-dashboards",
      },
      hoveredImage: {
        path: "solution---interactive-dashboards--hovered",
        alt: "solution---interactive-dashboards--hovered",
      },
    },
  ],
}

// Features Section
export const featuresSection = {
  title: "Key Features: Your Path To Efficiency And Growth",
  features: [
    {
      componentEC: "",
      title: "Effortless Client Onboarding",
      description:
        "Enhance your accounting practice with our easy-to-use, customisable form creation feature. It helps you gather all the necessary information from clients quickly and efficiently. You can tailor these forms to suit your specific needs, ensuring that the onboarding process is smooth and seamless. This means better communication and more accurate data collection from the start, helping you manage your clients more effectively.",
      buttonn: {
        to: featuresPostTypeSlug + "client-onboarding/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      widget: <ClientOnboarding />,
    },
    {
      componentEC: "",
      title: "Task Management and Reporting",
      description:
        "Streamline your accounting tasks with Synkli's powerful task management and reporting tools. These features let you easily assign tasks, track your team’s progress, and get detailed insights into their performance. You can see what everyone is working on, spot any bottlenecks, and ensure that tasks are completed on time. Also, you can generate comprehensive reports that help you understand how your practice is performing and where you can improve.",
      buttonn: {
        to: featuresPostTypeSlug + "task-management-and-reporting/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      widget: <TaskManagementAndReporting />,
    },
    {
      componentEC: "",
      title: "Client Management",
      description:
        "Keep all your client information organised and accessible with our intuitive client management feature. You can effortlessly collect and store client data, gather signatures, and manage documents all in one place. Each client has a dedicated file manager, allowing for real-time file sharing between you and your clients. This ensures that you always have the latest information at your fingertips and can communicate with your clients more efficiently.",
      buttonn: {
        to: featuresPostTypeSlug + "client-management/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      widget: <ClientManagement />,
    },
    {
      componentEC: "",
      title: "Expense & Income Management",
      description:
        "You can gain a clear understanding of your clients' financial situations with our detailed dashboards. These dashboards provide a visual overview of their income and expenses, making it easier to see their current financial position. You can also generate preprocessed profit and loss statements, rental schedules, or deduction summaries tailored to different types of clients, whether they are business owners, rental property owners, sole traders, or salaried individuals. This helps you provide better advice and ensure that your clients maximise their financial outcomes.",
      buttonn: {
        to: featuresPostTypeSlug + "expense-and-income-management/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      widget: <ExpenseAndIncomeManagement />,
    },
    {
      componentEC: "",
      title: "Synkli Capture",
      description: `Let’s revamp your WordPress site with SYNKLI Capture. This plugin seamlessly integrates with SYNKLI Portal, and its latest feature will streamline lead management by automatically syncing with your Portal and eliminating manual data entry work. Personalise your forms to capture relevant information and acquire instant updates on the SYNKLI dashboard. SYNKLI Capture refines your workflow. Focus on what matters—turning leads into loyal customers. Start with automated syncing and streamlined management today.`,
      buttonn: {
        to: featuresPostTypeSlug + "synkli-capture/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `synkli-wordpress-plugin`,
        alt: `synkli-wordpress-plugin`,
      },
    },
    {
      componentEC: "",
      title: "Synkli Add-In for Outlook",
      description: `Ready to boost productivity with a free Synkli add-in for Outlook? This feature smoothly combines with the Synkli portal, helping users manage leads directly from their Outlook. Simply use your existing Synkli account to automatically synchronise tasks and leads with the portal. Simplify your workflow and control everything from one location. The Synkli Add-In also offers a client lookup feature, helping you find complete information about your existing client saving considerable time.`,
      buttonn: {
        to: featuresPostTypeSlug + "outlook-addin/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `synkli-outlook-addin`,
        alt: `synkli-outlook-addin`,
      },
    },
    {
      componentEC: "",
      title: "Connect to Xero",
      description: `Let’s explore another exciting feature that seamlessly links Synkli with Xero. This synchronisation automatically sends your data to Xero in a minute. Gone are the days of inconsistencies or delays. You can access and update everything from any of the platforms. Whether you're reworking client information on Synkli or managing finances in Xero, the connected data ensures both systems are consistently accurate and up-to-date. This is time-saving and reduces the chance of errors. Now, concentrate on delivering outstanding service to your clients!`,
      buttonn: {
        to: featuresPostTypeSlug + "connect-to-xero/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `synkli-connect-to-zero`,
        alt: `synkli-connect-to-zero`,
      },
    },
    {
      componentEC: "",
      title: "Online Signature",
      description: `Seeking a client's signature has become more streamlined lately. It’s time for accountants to employ a paperless approach, and Synkli offers an online signature service. You can sign documents online quickly and securely, saving time and effort. Our digital signature functionality ensures your documents are legally binding and safely stored, enhancing the efficiency of client data management.`,
      buttonn: {
        to: featuresPostTypeSlug + "online-signature/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--online-signature-widget`,
        alt: `features-page--online-signature-widget`,
      },
    },
    {
      componentEC: "",
      title: "Claim Deductions",
      description: `Synkli’s ‘Scan and Go’ feature simplifies deduction claims by enabling businesses to scan and submit receipts instantly through a mobile app. It automatically organises and tracks expenses, allowing faster reimbursements and reducing manual errors. Accountants can review claims in real time, improving workflow efficiency and maximising tax savings. This tool helps businesses manage receipts on the go, streamlining the entire reimbursement process.`,
      buttonn: {
        to: featuresPostTypeSlug + "claim-deductions/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--claim-deductions`,
        alt: `features-page--claim-deductions`,
      },
    },
    {
      componentEC: "",
      title: "Mileage Tracker",
      description: `Days of manually logging your trips are over with Synkli’s trip tracker feature. Now, you can automatically track your mileage for business and personal trips. Effortlessly log and report every mile to ensure you maximise your deduction claims accurately and efficiently. Automate your trip expense reporting and maximise your tax benefits.`,
      buttonn: {
        to: featuresPostTypeSlug + "mileage-tracking/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--mileage-tracker`,
        alt: `features-page--mileage-tracker`,
      },
    },
    {
      componentEC: "",
      title: "Team Management",
      description: `Synkli’s Team Management feature helps accountants streamline their workflow by organising team roles, tracking progress, and ensuring smooth collaboration. Accountants can assign tasks, monitor performance, and centralise communication, making coordinating with team members and clients easy. With real-time updates and role management, Synkli enhances productivity and simplifies practice management, helping teams stay aligned and focused on delivering efficient client accounting services.`,
      buttonn: {
        to: featuresPostTypeSlug + "team-management/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--team-management`,
        alt: `features-page--team-management`,
      },
    },
    {
      componentEC: "",
      title: "Document Hub",
      description: `Document Hub, A file manager, simplifies accounting workflows by organising and storing client financial documents, including invoices and tax returns, in a single, secure location. Businesses and accountants can easily upload, review, and share files, facilitating collaboration and enhancing efficiency. The digital document-sharing tool supports online signatures from clients or multiple recipients for quick and efficient accounting record management and tax processes.`,
      buttonn: {
        to: featuresPostTypeSlug + "document-hub/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--document-hub`,
        alt: `features-page--document-hub`,
      },
    },
    {
      componentEC: "",
      title: "Job manager",
      description: `Synkli’s Job Manager helps accountants optimise accounting practice management through workflow automation and simplified task communication. Accountants can easily create, assign, and track jobs, set milestones, and manage recurring tasks. The platform facilitates secure sharing of client data and enhances collaboration through features like instant messaging and automated reminders. This improves efficiency, saves time, and reduces errors, helping accountants focus on higher-level tasks while ensuring client satisfaction.`,
      buttonn: {
        to: featuresPostTypeSlug + "job-manager/",
        href: "#",
        text: "Learn More",
        ec: "",
      },
      image: {
        path: `features-page--job-manager`,
        alt: `features-page--job-manager`,
      },
    },
  ],
}

// Testimonial Section
export const testimonialSection = {
  title: `What Our Users Love About Synkli`,
  description: `Learn more about how our app helps accountants and accounting companies streamline their day to day activities. Hear what makes them love us and why they don’t mind sticking around with us.`,
}

// Case Study Section
export const caseStudiesSection = {
  title: `Case Studies`,
  description: `Discover how our app has transformed business's and individuals' stories into success experiences. From diverse industries, learn how Synkli has simplified financial management, made tax deductions easier, and improved communication with accountants.`,
}

// Happy Customer Section
export const happyCustomerSection = {
  subTitle: `Happy Customer`,
  mainTitle: `A Day In The Life Of An Accountant`,
  title: `Improving client relationships and standing out against the crowd`,
  description: `“Using Synkli has completely transformed my daily workflow. Tasks that used to take hours are now completed in minutes. The intuitive client management and automated reporting features have significantly increased my efficiency and accuracy. I can focus more on providing valuable insights to my clients rather than getting bogged down by administrative tasks. Synkli is a game-changer for any accountant looking to streamline their practice.”`,
}

// Faqs Section
export const faqs = [
  {
    question: "Can my accounting company use Synkli as our major app?",
    answer:
      "Yes! Synkli is designed to be the central hub for managing your entire accounting firm. You can efficiently oversee all your clients and team members within a single application. Additionally, your clients can collaborate with you through Synkli, making it a comprehensive solution for accounting practices seeking streamlined operations and enhanced client interaction.",
  },
  {
    question: "Can I use it for more than one client?",
    answer:
      "Yes, Synkli is built to manage multiple clients seamlessly and simultaneously. As an accountant, you can use Synkli to handle all your clients' accounts efficiently. You can manage your team members, assign tasks, and collaborate with clients across various engagements, ensuring that your firm operates smoothly and effectively.",
  },
  {
    question: "Do I need to subscribe to interact with my clients?",
    answer:
      "To access and work with your clients' data through Synkli, you'll need a subscription. However, Synkli offers a free trial period so you can explore its features and functionalities before making a commitment.",
  },
  {
    question: "What’s the limit to the number of clients I can manage?",
    answer:
      "There is no limit to the number of clients you can manage using Synkli. Whether you have a small client base or a large portfolio, Synkli can accommodate your needs, as we have no restrictions whatsoever.",
  },
  {
    question: "What measures are in place to ensure data accuracy?",
    answer:
      "Synkli ensures data accuracy by maintaining the integrity of the data sent directly by your clients through the app. The platform does not alter any data as we ensure that you receive information exactly as your clients provide it.",
  },
  {
    question:
      "Is it easy to understand for my clients who aren’t knowledgeable in accounting?",
    answer:
      "You have no worries as the app is made for layman as well. Synkli's onboarding process is designed to be user-friendly, allowing anyone, regardless of their accounting knowledge, to set up their profile and provide necessary details easily.",
  },
  {
    question:
      "How can I set and manage access controls for different team members?",
    answer:
      "Synkli features a built-in role and permission module that allows you to assign specific roles to team members based on their responsibilities. You can easily manage access controls, granting or restricting permissions as needed, ensuring that sensitive client information is protected and managed securely.",
  },
  {
    question:
      "Are there any tutorials or support resources available for new users?",
    answer:
      "Synkli provides a dedicated support portal with comprehensive step-by-step guides to help new users navigate the platform effectively. Additionally, our support team is available to assist you with any queries or issues, ensuring a smooth onboarding experience and ongoing support.",
  },
  {
    question:
      "How does the app help with managing disorganised client records?",
    answer:
      "Synkli organises client data within individual profiles or entities such as trusts, companies, or partnerships. This structured approach makes it easy to locate and access relevant client records promptly. Each client's data is stored accurately, ensuring that you receive organised information that facilitates efficient accounting practices.",
  },
  {
    question:
      "Can I customise each prebuilt form/template or create new forms?",
    answer:
      "Yes, Synkli allows you to customise pre-built forms/templates according to your specific practice requirements. You also have the flexibility to create entirely new custom forms tailored to your needs, giving you full control over how client information is collected and managed within the platform.",
  },
  {
    question:
      "Can I import my clients' data from anywhere, or are there specific requirements?",
    answer:
      "Synkli offers a bulk import functionality that enables you to upload clients' data using a provided CSV file format. This feature allows you to import data from various sources seamlessly, ensuring flexibility and ease of integration into your accounting processes.",
  },
  {
    question:
      "Can I track the progress of multiple tasks simultaneously with the Task Management feature?",
    answer:
      "Yes, Synkli's Task Management feature allows you to view, manage, and track the progress of multiple tasks concurrently. This feature ensures that you can monitor ongoing tasks, prioritise assignments, and maintain efficient workflow management within your accounting practice.",
  },
  {
    question:
      "What’s the process of collecting client signatures using the app?",
    answer:
      "Within Synkli, you can use the file manager feature within each client's profile to request signatures on any PDF document of your choice. This process allows you to securely collect and manage client signatures directly through the platform, enhancing document management efficiency and client communication.",
  },
  // More questions...
]

// MobileAppLinks Section
export const mobileAppLinksSection = {
  title: `Maximising your tax returns with fast, easy and accurate data management`,
  description: `Download our mobile app to ensure you have accurate record keeping and an increase in your tax refunds. With Synkli, you can explore many accounting tools and features.`,
}
